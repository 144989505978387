import { useRef, useState } from "react";
import {
  Avatar,
  Button,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Tooltip,
  Typography,
  Select,
  MenuItem,
  Checkbox,
} from "@material-ui/core";
import useBranches from "../../hooks/useBranches";
import { useSelector, useDispatch } from "../../store";
import { setSelectedBranch } from "../../slices/selectedBranch";
import PropTypes from "prop-types";

const NotificationsPopover = () => {
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const branches = useBranches();
  const { selectedBranch } = useSelector((state) => state.selectedBranch);
  const [selectedBranchIds, setSelectedBranchIds] = useState(selectedBranch);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const BranchOption = ({ branch }) => {
    const [checked, setChecked] = useState(
      selectedBranchIds.includes(branch._id)
    );

    return (
      <ListItem
        divider
        // onClick={() => dispatch(setSelectedBranch(branch._id))}
        onClick={() => {
          setChecked(!checked);
          if (checked) {
            setSelectedBranchIds(
              selectedBranchIds.filter((id) => id !== branch._id)
            );
          } else {
            setSelectedBranchIds([...selectedBranchIds, branch._id]);
          }
        }}
        sx={{
          cursor: "pointer",
          "&:hover": {
            background: "#223b36",
          },
        }}
      >
        <ListItemAvatar>
          <Avatar
            src={branch.logoUrl}
            sx={{
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <Link
              color='textPrimary'
              sx={{ cursor: "pointer" }}
              underline='none'
              variant='subtitle2'
            >
              {branch.identifier ? (branch.name.concat(" - ", branch.identifier)) : (branch.name)}
            </Link>
          }
          secondary={branch.description}
        />
        <Checkbox checked={checked} />
      </ListItem>
    );
  };

  BranchOption.propTypes = {
    branch: PropTypes.object.isRequired,
  };

  const AllBranchesButton = () => {
    const onClick = () => {
      if (selectedBranchIds.length === branches.length) {
        setSelectedBranchIds([]);
      } else {
        setSelectedBranchIds(branches.map((branch) => branch._id));
      }
    };

    return (
      <ListItem
        divider
        onClick={onClick}
        sx={{
          cursor: "pointer",
          "&:hover": {
            background: "#223b36",
          },
        }}
      >
        <ListItemText
          primary={
            <Link
              color='textPrimary'
              sx={{ cursor: "pointer" }}
              underline='none'
              variant='subtitle2'
            >
              Tüm Şubeler
            </Link>
          }
        />
        <Checkbox checked={selectedBranchIds.length === branches.length} />
      </ListItem>
    );
  };

  const ChangeSelectedBranchesButton = () => {
    const onClick = () => {
      if (selectedBranchIds.length > 0) {
        dispatch(setSelectedBranch(selectedBranchIds));
      }
    };

    return (
      <ListItem
        divider
        onClick={onClick}
        sx={{
          padding: 0,
          justifyContent: "center",
          backgroundColor: "primary.main",
        }}
      >
        <Button
          disabled={selectedBranchIds.length === 0}
          sx={{
            width: "100%",
            height: "100%",
            color: "primary.contrastText",
          }}
        >
          Seçili Şubeleri Değiştir
        </Button>
      </ListItem>
    );
  };

  return (
    <>
      <Tooltip title='Şubeler'>
        <Select
          renderValue={(selected) => {
            if (selected.length === branches.length) {
              return "Tüm Şubeler";
            }
            const selectedBranches = selected.map((branchId) =>
              branches.find((branch) => branch._id === branchId)
            );
            return selectedBranches.map(({ name, identifier }) => identifier ? (name.concat(" - ", identifier)) : (name)).join(", ");
          }}
          multiple
          value={selectedBranchIds}
          ref={anchorRef}
          onClick={handleOpen}
          open={false}
          SelectDisplayProps={{
            multiple: true,
            open: false,
          }}
        >
          {branches.map((branch) => (
            <MenuItem value={branch._id} key={branch._id}>
              {branch.identifier ? (branch.name.concat(" - ", branch.identifier)) : (branch.name)}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: "center",
          vertical: "bottom",
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 320,
            marginLeft: -7,
            "&:last-child li": {
              border: 0,
            },
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography color='textPrimary' variant='h6'>
            Şubeler
          </Typography>
        </Box>
        <>
          <List disablePadding>
            {branches.map((branch) => (
              <BranchOption branch={branch} key={branch._id} />
            ))}
            <AllBranchesButton />
            <ChangeSelectedBranchesButton />
          </List>
        </>
      </Popover>
    </>
  );
};

export default NotificationsPopover;
