import { useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, Box, Divider, Drawer, Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReceiptIcon from "@material-ui/icons/Receipt";
import useAuth from "../../hooks/useAuth";
import BriefcaseIcon from "../../icons/Briefcase";
// import ChartPieIcon from "../../icons/ChartPie";
import ChartSquareBarIcon from "../../icons/ChartSquareBar";
import FolderOpenIcon from "../../icons/Refresh";
// import ShoppingBagIcon from "../../icons/ShoppingBag";
import ShoppingCartIcon from "../../icons/ShoppingCart";
import ProductIcon from "../../icons/Archive";
// import DeviceIcon from "../../icons/DeviceTablet";
import UsersIcon from "../../icons/Users";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import TableRestaurantIcon from "@material-ui/icons/TableRows";
import CogIcon from "../../icons/Cog";
import Logo from "../Logo";
import NavSection from "../NavSection";
import Scrollbar from "../Scrollbar";
import useBusiness from "../../hooks/useBusiness";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import ContactlessIcon from "@material-ui/icons/Contactless";

const DashboardSidebar = (props) => {
  const { businessType } = useBusiness();
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const tablesVisible = businessType === "Restaurant";
  const stationsVisible = businessType === "FuelStation";
  const sections = [
    {
      title: "Genel",
      visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
      items: [
        {
          title: "Genel Bakış",
          path: "/dashboard",
          icon: <ChartSquareBarIcon fontSize='small' />,
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
        },
        // {
        //   title: "Analitik",
        //   path: "/dashboard/analytics",
        //   icon: <ChartPieIcon fontSize='small' />,
        // },
        // {
        //   title: "Finans",
        //   path: "/dashboard/finance",
        //   icon: <ShoppingBagIcon fontSize='small' />,
        // },
        {
          title: "Ayarlar",
          path: "/dashboard/account",
          icon: <CogIcon fontSize='small' />,
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
        },
      ],
    },
    {
      title: "Detaylar",
      visibleBy: ["LevelOne", "LevelTwo"],
      items: [
        {
          title: "Kasalar",
          icon: <ContactlessIcon fontSize='small' />,
          path: "/dashboard/devices",
          visibleBy: ["LevelOne", "LevelTwo"],
          children: [
            {
              title: "Liste",
              visibleBy: ["LevelOne", "LevelTwo"],
              path: "/dashboard/devices/list",
            },
          ],
        },

        {
          title: "Çalışanlar",
          icon: <UsersIcon fontSize='small' />,
          path: "/dashboard/employees",
          visibleBy: ["LevelOne", "LevelTwo,LevelThree"],
          children: [
            {
              title: "Liste",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/employees/list",
            },
            {
              title: "Oluştur",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/employees/create",
            },
          ],
        },
        {
          title: "Ödemeler",
          icon: <ShoppingCartIcon fontSize='small' />,
          path: "/dashboard/orders",
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          children: [
            {
              title: "Liste",
              path: "/dashboard/orders",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
            },
            {
              title: "Arama",
              path: "/dashboard/orders/search",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
            },
          ],
        },
        {
          title: "İadeler",
          icon: <FolderOpenIcon fontSize='small' />,
          path: "/dashboard/refunds",
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          children: [
            {
              title: "Liste",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/refunds",
            },
            {
              title: "Arama",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/refunds/search",
            },
            {
              title: "İade Talepleri",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/refunds/requested",
            },
          ],
        },
        {
          title: "İndirimler",
          path: "/dashboard/campign",
          icon: <ReceiptIcon fontSize='small' />,
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          children: [
            {
              title: "Liste",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/campaign/list",
            },
            {
              title: "Oluştur",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/campaign/create",
            },
          ],
        },
        {
          title: "Kuponlar",
          path: "/dashboard/coupons",
          icon: <LocalOfferIcon fontSize='small' />,
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          children: [
            {
              title: "Liste",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/coupons/list",
            },
            {
              title: "Oluştur",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/coupons/create",
            },
          ],
        },
        /* {
          title: "Hızlı Kupon",
          path: "/dashboard/rapid-coupon",
          icon: <MyLocationIcon fontSize='small' />,
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          children: [
            {
              title: "Liste",
              visibleBy: ["LevelOne", "LevelTwo"],
              path: "/dashboard/rapid-coupon/list",
            },
            {
              title: "Gönder",
              visibleBy: ["LevelOne", "LevelTwo"],
              path: "/dashboard/rapid-coupon/create",
            },
          ],
        },
        {
          title: "Ürünler",
          path: "/dashboard/products",
          icon: <ProductIcon fontSize='small' />,
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          children: [
            {
              title: "Liste",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/products/list",
            },
            {
              title: "Oluştur",
              visibleBy: ["LevelOne", "LevelTwo"],
              path: "/dashboard/products/create",
            },
          ],
        }, */
      ],
    },
    {
      title: "İŞ YERİ",
      visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
      items: [
        {
          title: "Şubeler",
          path: "/dashboard/branch",
          icon: <BriefcaseIcon fontSize='small' />,
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          children: [
            {
              title: "Liste",
              visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
              path: "/dashboard/branch/list",
            },
            // {
            //   title: 'Oluştur',
            //   path: '/dashboard/projects/new',
            // },
          ],
        },
        // {
        //   title: "Social",
        //   path: "/dashboard/rapid-coupon",
        //   icon: <UsersIcon fontSize='small' />,
        //   visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
        //   children: [
        //     {
        //       visibleBy: ["LevelOne", "LevelTwo"],
        //       title: "Liste",
        //       path: "/dashboard/rapid-coupon/list",
        //     },
        //     {
        //       visibleBy: ["LevelOne", "LevelTwo"],
        //       title: "Oluştur",
        //       path: "/dashboard/rapid-coupon/create",
        //     },
        //   ],
        // },
      ],
    },
    // {
    //   title: "Apps",
    //   visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],

    //   items: [
    //     {
    //       title: "Kanban",
    //       path: "/dashboard/kanban",
    //       visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],

    //       icon: <UsersIcon fontSize='small' />,
    //     },
    //     {
    //       title: "Mail",
    //       visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],

    //       path: "/dashboard/mail",
    //       icon: <UsersIcon fontSize='small' />,
    //     },
    //     {
    //       title: "Chat",
    //       visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],

    //       path: "/dashboard/chat",
    //       icon: <UsersIcon fontSize='small' />,
    //     },
    //     {
    //       visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],

    //       title: "Calendar",
    //       path: "/dashboard/calendar",
    //       icon: <UsersIcon fontSize='small' />,
    //     },
    //   ],
    // },
  ];

  if (tablesVisible) {
    sections[1].items.unshift({
      title: "Masalar",
      visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
      icon: <TableRestaurantIcon fontSize='small' />,
      path: "/dashboard/tables",
      children: [
        {
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          title: "Liste",
          path: "/dashboard/tables/list",
        },
      ],
    });
  }

  if (stationsVisible) {
    sections[1].items.unshift({
      title: "İstasyonlar",
      icon: <LocalGasStationIcon fontSize='small' />,
      visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
      path: "/dashboard/tables",
      children: [
        {
          title: "Liste",
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          path: "/dashboard/stations/list",
        },
        {
          title: "Oluştur",
          visibleBy: ["LevelOne", "LevelTwo", "LevelThree"],
          path: "/dashboard/stations/create",
        },
      ],
    });
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: "none",
              xs: "flex",
            },
            justifyContent: "center",
            p: 2,
          }}
        >
          <RouterLink to='/'>
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: "center",
              backgroundColor: "background.default",
              borderRadius: 1,
              display: "flex",
              overflow: "hidden",
              p: 2,
            }}
          >
            <RouterLink to='/dashboard/account'>
              <Avatar
                src={user.avatar}
                sx={{
                  cursor: "pointer",
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography color='textPrimary' variant='subtitle2'>
                {user.name}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                "& + &": {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
        <Divider />
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor='left'
        open
        PaperProps={{
          sx: {
            backgroundColor: "background.paper",
            height: "calc(100% - 64px) !important",
            top: "64px !Important",
            width: 280,
          },
        }}
        variant='permanent'
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor='left'
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: "background.paper",
          width: 280,
        },
      }}
      variant='temporary'
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
