import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField } from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { useNavigate } from 'react-router-dom';

const LoginFirebase = (props) => {
  const navigate = useNavigate();
  const mounted = useMounted();
  const { signInWithEmailAndPassword } = useAuth();

  return (
    <div {...props}>
      <Formik
        initialValues={{
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Geçerli bir e-posta girin')
            .max(255)
            .required('E-posta zorunludur'),
          password: Yup.string().max(255).required('Şifre zorunludur'),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            await signInWithEmailAndPassword(values.email, values.password);

            if (mounted.current) {
              navigate('/dashboard');
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            console.error(err);
            if (mounted.current) {
              setStatus({ success: false });
              setErrors({
                submit: 'Hatalı e-posta veya şifre! Lütfen tekrar deneyiniz.',
              });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit} {...props}>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="E-posta"
              margin="normal"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Şifre"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>{errors.submit}</FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <Button
                color="primary"
                disabled={isSubmitting}
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Giriş Yap
              </Button>
            </Box>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginFirebase;
