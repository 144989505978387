import axios from "../axios";
import Config from "../apiConfig";

const endpoint = `${Config.url}employee`;

class EmployeeService {
  async getEmployeeWithId(employeeId) {
    try {
      const response = await axios.get(`${endpoint}/${employeeId}`);
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // async createEmployeeInFirebase() {
  //   try {
  //     const response = await axios.get(`${endpoint}/getEmployee`);
  //     return response.data.message;
  //   } catch (error) {
  //     console.error(error);
  //     throw error;
  //   }
  // }

  async loginEmployeeWithFirebase() {
    try {
      const response = await axios.get(`${endpoint}/`);
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateEmployee({
    employeeToEdit,
    isActive,
    employeeType,
    devices,
    businesses,
    stations,
    deleteBusiness,
    deleteDevice,
    deleteStation,
    isResetPasswordNeeded,
  }) {
    try {
      const response = await axios.put(`${endpoint}`, {
        employeeToEdit,
        isActive,
        employeeType,
        devices,
        businesses,
        stations,
        deleteBusiness,
        deleteDevice,
        deleteStation,
        isResetPasswordNeeded,
      });
      return response.data.message;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async updateEmployeeInfo(updates) {
    try {
      const response = await axios.put(`${endpoint}/profile`, {
        update: updates,
      });
      return response.data.message;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async getBusinessEmployees(limit, skip, business, text) {
    try {
      const response = await axios.get(`${endpoint}/businessEmployees/`, {
        params: {
          business,
          text,
          limit: limit + 1,
          skip,
        },
      });
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createEmployee(values) {
    try {
      const response = await axios.post(`${endpoint}/`, values);
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deactiveEmployee(employeeToEdit, isActive) {
    try {
      const response = await axios.put(`${endpoint}`, {
        employeeToEdit,
        isActive,
      });
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new EmployeeService();
