import LogoSrc from '../assets/zippy.png';

// const LogoRoot = experimentalStyled('svg')``;

const Logo = () => (
  <img
    src={LogoSrc}
    alt="Zippy Pay"
    style={{ width: '156px', height: 'auto' }}
  />
);

export default Logo;
