export const THEMES = {
  LIGHT: "LIGHT",
  DARK: "DARK",
  NATURE: "NATURE",
};

export const TURKISH_THEMES = {
  Light: "AÇIK",
  Dark: "KOYU",
  Nature: "DOĞAL",
};

export const EMPLOYEE_TYPES = [
  { label: "Seviye Bir", value: "LevelOne" },
  { label: "Seviye İki", value: "LevelTwo" },
  { label: "Seviye Üç", value: "LevelThree" },
];

export const BUSINESS_TYPES = [
  { value: "Pharmacy", label: "Eczane" },
  { value: "Fashion", label: "Moda" },
  { value: "Market", label: "Market" },
  { value: "Cosmetics", label: "Kozmetik" },
  { value: "Restaurant", label: "Restoran" },
  { value: "Taxi", label: "Taksi" },
  { value: "FuelStation", label: "Benzin Istasyonu" },
];

export const MONTHS = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
];
