import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import firebase from "../lib/firebase";
import EmployeeServices from "../API/Employee/services";
import BusinessServices from "../API/Business/services";
import BranchServices from "../API/Branch/services";
import { useDispatch } from "../store";
import { setSelectedBranch } from "../slices/selectedBranch";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === "AUTH_STATE_CHANGED") {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  platform: "Firebase",
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const branchDispatch = useDispatch();

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          const fullData = await EmployeeServices.loginEmployeeWithFirebase();
          const {
            firstName,
            lastName,
            roles,
            isActive,
            registeredBusiness,
            _id,
            employeeType,
          } = fullData;

          const business = await BusinessServices.getBusinessData(
            registeredBusiness._id
          );

          const businessBranches = await BranchServices.getBusinessBranches();
          const initialBranch =
            businessBranches.length > 1
              ? businessBranches.filter((branch) => branch.isParent)[0]._id
              : businessBranches[0]._id;
          branchDispatch(setSelectedBranch([initialBranch]));

          dispatch({
            type: "AUTH_STATE_CHANGED",
            payload: {
              isAuthenticated: true,
              user: {
                uid: user.uid,
                avatar: user.photoURL,
                email: user.email,
                isResetPasswordNeeded: fullData.isResetPasswordNeeded,
                name: `${firstName} ${lastName}`,
                roles,
                isActive,
                business: { ...business, branches: businessBranches },
                id: _id,
                employeeType,
              },
            },
          });
        } else {
          dispatch({
            type: "AUTH_STATE_CHANGED",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      }),
    [dispatch]
  );

  const signInWithEmailAndPassword = (email, password) =>
    firebase.auth().signInWithEmailAndPassword(email, password);

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const createUserWithEmailAndPassword = async (email, password) =>
    firebase.auth().createUserWithEmailAndPassword(email, password);

  const logout = async () => {
    await firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "Firebase",
        createUserWithEmailAndPassword,
        signInWithEmailAndPassword,
        signInWithGoogle,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
