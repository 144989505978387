import { useEffect, useState } from "react";
import Helmet from "../../components/Helmet";
import {
  Box,
  Card,
  CardContent,
  Container,
  Button,
  Typography,
  TextField,
} from "@material-ui/core";
import Logo from "../../components/Logo";
import gtm from "../../lib/gtm";
import EmployeeService from "../../API/Employee/services";
import toast from "react-hot-toast";
import firebase from "firebase";
import useAuth from "../../hooks/useAuth";

const PasswordReset = () => {
  const auth = useAuth();
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState("");
  const [loading, setLoading] = useState(false);

  const changePassword = async () => {
    setLoading(true);
    try {
      if (newPassword !== newPasswordRepeat) {
        toast.error("Şifreler Uyuşmamaktadır");
      } else {
        const user = firebase.auth().currentUser;
        await user.updatePassword(newPassword);
        await EmployeeService.updateEmployeeInfo({
          isResetPasswordNeeded: false,
        });
        await auth.logout();
      }
    } catch (error) {
      toast.error(error.message || "Bir seyler ters gitti!");
    }
    setLoading(false);
  };

  useEffect(() => {
    gtm.push({ event: "page_view" });
  }, []);

  return (
    <>
      <Helmet title='Şifre Sıfırlama' />

      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        <Container maxWidth='sm' sx={{ py: 10 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mb: 8,
            }}
          />
          <Card>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 3,
                }}
              >
                <div>
                  <Typography color='textPrimary' gutterBottom variant='h4'>
                    Şifre Sıfırlama
                  </Typography>
                  <Typography color='textSecondary' variant='body2'>
                    Hesaba ilk defa girdiğiniz için şifrenizi sıfırlamanız
                    gerekmektedir.
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 1,
                  mb: 3,
                }}
              >
                <TextField
                  onChange={({ target }) => setNewPassword(target.value)}
                  value={newPassword}
                  placeholder='Yeni Şifre'
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 1,
                  mb: 3,
                }}
              >
                <TextField
                  onChange={({ target }) => setNewPasswordRepeat(target.value)}
                  value={newPasswordRepeat}
                  placeholder='Yeni Şifre Tekrar'
                  sx={{
                    width: "100%",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  disabled={
                    loading || newPassword === "" || newPasswordRepeat === ""
                  }
                  variant='contained'
                  onClick={changePassword}
                >
                  Sıfırla
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default PasswordReset;
