export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: 'AIzaSyC55aoRSwwJZY-jIXqGNE8tPqWiOvIYyeQ',
  authDomain: 'zippypaymobile.firebaseapp.com',
  projectId: 'zippypaymobile',
  storageBucket: 'zippypaymobile.appspot.com',
  messagingSenderId: '963723594690',
  appId: '1:963723594690:web:214ea1bb32cf5c45e5245b',
  measurementId: 'G-DD6Q0D9MZC',
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};
