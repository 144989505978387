import axios from "../axios";
import Config from "../apiConfig";

const endpoint = `${Config.url}branch`;

class BranchService {
  async getBusinessBranches() {
    try {
      const response = await axios.get(`${endpoint}`);
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateBranch(business, update) {
    try {
      const response = await axios.put(`${endpoint}`, {
        business,
        update,
      });
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new BranchService();
