/* eslint-disable indent */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedBranch: [],
};

const slice = createSlice({
  name: "selectedBranch",
  initialState,
  reducers: {
    setSelectedBranch(state, action) {
      const newBranch = action.payload;
      state.selectedBranch = newBranch;
    },
  },
});

export const { reducer } = slice;

export const setSelectedBranch = (branchId) => (dispatch) => {
  dispatch(slice.actions.setSelectedBranch(branchId));
};

export default slice;
