import axios from "../axios";
import Config from "../apiConfig";
import toast from "react-hot-toast";

const endpoint = `${Config.url}business`;
class BusinessService {
  async getBusinessData(business) {
    try {
      const response = await axios.get(`${endpoint}/${business}`);
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateBusinessPhoto(business, photo, photoType) {
    // photoType: logo or image
    try {
      const response = await axios.put(`${endpoint}/photo`, {
        business,
        photo,
        photoType,
      });
      return response.data.message;
    } catch (error) {
      toast.error(error.response.data.message);
      throw error;
    }
  }

  async deactiveBusiness(business) {
    try {
      const response = await axios.put(`${endpoint}`, {
        business,
        update: { isActive: false },
      });
      return response.data.message;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default new BusinessService();
