import { Suspense, lazy } from "react";
// import { Navigate } from "react-router-dom";
import AuthGuard from "./components/AuthGuard";
// import BlogLayout from "./components/blog/BlogLayout";
// import BrowseLayout from "./components/BrowseLayout";
import DashboardLayout from "./components/dashboard/DashboardLayout";
// import DocsLayout from "./components/docs/DocsLayout";
import GuestGuard from "./components/GuestGuard";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Browse pages

// const Browse = Loadable(lazy(() => import("./pages/browse/Browse")));
// const BrowseButtons = Loadable(
//   lazy(() => import("./pages/browse/BrowseButtons"))
// );
// const BrowseCharts = Loadable(
//   lazy(() => import("./pages/browse/BrowseCharts"))
// );
// const BrowseColors = Loadable(
//   lazy(() => import("./pages/browse/BrowseColors"))
// );
// const BrowseDetailLists = Loadable(
//   lazy(() => import("./pages/browse/BrowseDetailLists"))
// );
// const BrowseForms = Loadable(lazy(() => import("./pages/browse/BrowseForms")));
// const BrowseGridLists = Loadable(
//   lazy(() => import("./pages/browse/BrowseGridLists"))
// );
// const BrowseGroupedLists = Loadable(
//   lazy(() => import("./pages/browse/BrowseGroupedLists"))
// );
// const BrowseInputs = Loadable(
//   lazy(() => import("./pages/browse/BrowseInputs"))
// );
// const BrowseModals = Loadable(
//   lazy(() => import("./pages/browse/BrowseModals"))
// );
// const BrowseQuickStats = Loadable(
//   lazy(() => import("./pages/browse/BrowseQuickStats"))
// );
// const BrowseTables = Loadable(
//   lazy(() => import("./pages/browse/BrowseTables"))
// );
// const BrowseTypography = Loadable(
//   lazy(() => import("./pages/browse/BrowseTypography"))
// );

// Authentication pages

const Login = Loadable(lazy(() => import("./pages/authentication/Login")));
const PasswordRecovery = Loadable(
  lazy(() => import("./pages/authentication/PasswordRecovery"))
);
const PasswordReset = Loadable(
  lazy(() => import("./pages/authentication/PasswordReset"))
);
const Register = Loadable(
  lazy(() => import("./pages/authentication/Register"))
);
// const VerifyCode = Loadable(
//   lazy(() => import("./pages/authentication/VerifyCode"))
// );

// Blog pages

// const BlogPostCreate = Loadable(
//   lazy(() => import("./pages/blog/BlogPostCreate"))
// );
// const BlogPostDetails = Loadable(
//   lazy(() => import("./pages/blog/BlogPostDetails"))
// );
// const BlogPostList = Loadable(lazy(() => import("./pages/blog/BlogPostList")));

// Dashboard pages

const Account = Loadable(lazy(() => import("./pages/dashboard/Account")));
// const Analytics = Loadable(lazy(() => import("./pages/dashboard/Analytics")));
// const Calendar = Loadable(lazy(() => import("./pages/dashboard/Calendar")));
// const Chat = Loadable(lazy(() => import("./pages/dashboard/Chat")));
// const CustomerDetails = Loadable(
//   lazy(() => import('./pages/dashboard/CustomerDetails'))
// );
const DeviceCreate = Loadable(
  lazy(() => import("./pages/dashboard/DeviceCreate"))
);
const DevicesList = Loadable(
  lazy(() => import("./pages/dashboard/DevicesList"))
);

const StationCreate = Loadable(
  lazy(() => import("./pages/dashboard/StationCreate"))
);
const StationsList = Loadable(
  lazy(() => import("./pages/dashboard/StationList"))
);

// const Finance = Loadable(lazy(() => import("./pages/dashboard/Finance")));
const CampaignCreate = Loadable(
  lazy(() => import("./pages/dashboard/DiscountCreate"))
);
const CampaignList = Loadable(
  lazy(() => import("./pages/dashboard/DiscountList"))
);
const CouponCreate = Loadable(
  lazy(() => import("./pages/dashboard/CouponCreate"))
);
const CouponsList = Loadable(
  lazy(() => import("./pages/dashboard/CouponList"))
);

const RapidCouponCreate = Loadable(
  lazy(() => import("./pages/dashboard/RapidCouponCreate"))
);
const RapidCouponsList = Loadable(
  lazy(() => import("./pages/dashboard/RapidCouponList"))
);
// const Kanban = Loadable(lazy(() => import("./pages/dashboard/Kanban")));
// const Mail = Loadable(lazy(() => import("./pages/dashboard/Mail")));
const OrderDetails = Loadable(
  lazy(() => import("./pages/dashboard/OrderDetails"))
);
const OrderList = Loadable(lazy(() => import("./pages/dashboard/OrderList")));
const OrderSearch = Loadable(
  lazy(() => import("./pages/dashboard/OrderSearch"))
);
const RefundsList = Loadable(
  lazy(() => import("./pages/dashboard/RefundList"))
);
const RefundsSearch = Loadable(
  lazy(() => import("./pages/dashboard/RefundSearch"))
);
const RefundsRequestedList = Loadable(
  lazy(() => import("./pages/dashboard/RefundRequestedList"))
);
const Overview = Loadable(lazy(() => import("./pages/dashboard/Overview")));
const EmployeeCreate = Loadable(
  lazy(() => import("./pages/dashboard/EmployeeCreate"))
);
const EmployeesList = Loadable(
  lazy(() => import("./pages/dashboard/EmployeeList"))
);
const EmployeeDetails = Loadable(
  lazy(() => import("./pages/dashboard/EmployeeDetails"))
);

const ProductList = Loadable(
  lazy(() => import("./pages/dashboard/ProductsList"))
);

const ProductCreate = Loadable(
  lazy(() => import("./pages/dashboard/ProductsCreate"))
);

const ProductEdit = Loadable(
  lazy(() => import("./pages/dashboard/ProductEdit"))
);

const TableList = Loadable(lazy(() => import("./pages/dashboard/TableList")));

// Docs pages

// const Docs = Loadable(lazy(() => import("./pages/Docs")));

// Error pages

// const AuthorizationRequired = Loadable(
//   lazy(() => import("./pages/AuthorizationRequired"))
// );
// const NotFound = Loadable(lazy(() => import("./pages/NotFound")));
// const ServerError = Loadable(lazy(() => import("./pages/ServerError")));

// Projects pages

const BranchesList = Loadable(
  lazy(() => import("./pages/dashboard/BranchesList"))
);
const BranchDetails = Loadable(
  lazy(() => import("./pages/dashboard/BranchDetails"))
);
const ProjectCreate = Loadable(
  lazy(() => import("./pages/dashboard/ProjectCreate"))
);
const ProjectDetails = Loadable(
  lazy(() => import("./pages/dashboard/ProjectDetails"))
);

// Social pages

const SocialFeed = Loadable(lazy(() => import("./pages/dashboard/SocialFeed")));
const SocialProfile = Loadable(
  lazy(() => import("./pages/dashboard/SocialProfile"))
);

// Other pages

// const Checkout = Loadable(lazy(() => import("./pages/Checkout")));
// const Contact = Loadable(lazy(() => import("./pages/Contact")));
const Home = Loadable(lazy(() => import("./pages/Home")));
// const Pricing = Loadable(lazy(() => import("./pages/Pricing")));

const routes = [
  {
    path: "authentication",
    children: [
      {
        path: "login",
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: "login-unguarded",
        element: <Login />,
      },
      {
        path: "password-recovery",
        element: <PasswordRecovery />,
      },
      {
        path: "password-reset",
        element: <PasswordReset />,
      },
      {
        path: "register",
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      // {
      //   path: "register-unguarded",
      //   element: <Register />,
      // },
      // {
      //   path: "verify-code",
      //   element: <VerifyCode />,
      // },
    ],
  },
  // {
  //   path: "blog",
  //   element: <BlogLayout />,
  //   children: [
  //     {
  //       path: "/",
  //       element: <BlogPostList />,
  //     },
  //     {
  //       path: "new",
  //       element: <BlogPostCreate />,
  //     },
  //     {
  //       path: ":postId",
  //       element: <BlogPostDetails />,
  //     },
  //   ],
  // },
  // {
  //   path: "contact",
  //   element: <Contact />,
  // },
  {
    path: "dashboard",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/",
        element: <Overview />,
      },
      {
        path: "account",
        element: <Account />,
      },
      // {
      //   path: "analytics",
      //   element: <Analytics />,
      // },
      // {
      //   path: "calendar",
      //   element: <Calendar />,
      // },
      // {
      //   path: "chat",
      //   children: [
      //     {
      //       path: "/",
      //       element: <Chat />,
      //     },
      //     {
      //       path: "new",
      //       element: <Chat />,
      //     },
      //     {
      //       path: ":threadKey",
      //       element: <Chat />,
      //     },
      //   ],
      // },
      {
        path: "devices",
        children: [
          {
            path: "/list",
            element: <DevicesList />,
          },
          // {
          //   path: ':customerId',
          //   element: <CustomerDetails />,
          // },
          {
            path: "/create",
            element: <DeviceCreate />,
          },
        ],
      },
      {
        path: "stations",
        children: [
          {
            path: "/list",
            element: <StationsList />,
          },
          // {
          //   path: ':customerId',
          //   element: <CustomerDetails />,
          // },
          {
            path: "/create",
            element: <StationCreate />,
          },
        ],
      },
      {
        path: "campaign",
        children: [
          {
            path: "/list",
            element: <CampaignList />,
          },
          {
            path: "/create",
            element: <CampaignCreate />,
          },
        ],
      },
      {
        path: "coupons",
        children: [
          {
            path: "/list",
            element: <CouponsList />,
          },
          {
            path: "/create",
            element: <CouponCreate />,
          },
        ],
      },
      {
        path: "rapid-coupon",
        children: [
          {
            path: "/list",
            element: <RapidCouponsList />,
          },
          {
            path: "/create",
            element: <RapidCouponCreate />,
          },
        ],
      },
      // {
      //   path: "kanban",
      //   element: <Kanban />,
      // },
      // {
      //   path: "mail",
      //   children: [
      //     {
      //       path: "/",
      //       element: <Navigate to='/dashboard/mail/all' replace />,
      //     },
      //     {
      //       path: "label/:customLabel",
      //       element: <Mail />,
      //     },
      //     {
      //       path: "label/:customLabel/:emailId",
      //       element: <Mail />,
      //     },
      //     {
      //       path: ":systemLabel",
      //       element: <Mail />,
      //     },
      //     {
      //       path: ":systemLabel/:emailId",
      //       element: <Mail />,
      //     },
      //   ],
      // },
      {
        path: "orders",
        children: [
          {
            path: "/",
            element: <OrderList />,
          },
          {
            path: "/search",
            element: <OrderSearch />,
          },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
        ],
      },
      {
        path: "refunds",
        children: [
          {
            path: "/",
            element: <RefundsList />,
          },
          {
            path: "/search",
            element: <RefundsSearch />,
          },
          {
            path: ":orderId",
            element: <OrderDetails />,
          },
          {
            path: "/requested",
            element: <RefundsRequestedList />,
          },
        ],
      },
      // {
      //   path: "finance",
      //   element: <Finance />,
      // },
      {
        path: "employees",
        children: [
          {
            path: "/list",
            element: <EmployeesList />,
          },
          {
            path: "/create",
            element: <EmployeeCreate />,
          },
          {
            path: "list/:employeeId",
            element: <EmployeeDetails />,
          },
        ],
      },
      {
        path: "products",
        children: [
          {
            path: "/list",
            element: <ProductList />,
          },
          {
            path: "/create",
            element: <ProductCreate />,
          },
          {
            path: "edit/:employeeId",
            element: <ProductEdit />,
          },
        ],
      },
      {
        path: "tables",
        children: [
          {
            path: "/list",
            element: <TableList />,
          },
          {
            path: "/create",
            element: <ProductCreate />,
          },
          {
            path: "list/:employeeId",
            element: <ProjectDetails />,
          },
        ],
      },
      {
        path: "branch",
        children: [
          {
            path: "list",
            element: <BranchesList />,
          },
          {
            path: "new",
            element: <ProjectCreate />,
          },
          {
            path: "list/:projectId",
            element: <BranchDetails />,
          },
        ],
      },
      {
        path: "social",
        children: [
          {
            path: "feed",
            element: <SocialFeed />,
          },
          {
            path: "profile",
            element: <SocialProfile />,
          },
        ],
      },
    ],
  },
  // {
  //   path: "docs",
  //   element: <DocsLayout />,
  //   children: [
  //     {
  //       path: "/",
  //       element: <Navigate to='/docs/overview/welcome' replace />,
  //     },
  //     {
  //       path: "*",
  //       element: <Docs />,
  //     },
  //   ],
  // },
  {
    path: "*",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      //     {
      //       path: "browse",
      //       element: <BrowseLayout />,
      //       children: [
      //         {
      //           path: "/",
      //           element: <Browse />,
      //         },
      //         {
      //           path: "/buttons",
      //           element: <BrowseButtons />,
      //         },
      //         {
      //           path: "/inputs",
      //           element: <BrowseInputs />,
      //         },
      //         {
      //           path: "/charts",
      //           element: <BrowseCharts />,
      //         },
      //         {
      //           path: "/colors",
      //           element: <BrowseColors />,
      //         },
      //         {
      //           path: "/data-display/detail-lists",
      //           element: <BrowseDetailLists />,
      //         },
      //         {
      //           path: "/data-display/quick-stats",
      //           element: <BrowseQuickStats />,
      //         },
      //         {
      //           path: "/data-display/tables",
      //           element: <BrowseTables />,
      //         },
      //         {
      //           path: "/forms",
      //           element: <BrowseForms />,
      //         },
      //         {
      //           path: "/modals",
      //           element: <BrowseModals />,
      //         },
      //         {
      //           path: "/lists/grouped-lists",
      //           element: <BrowseGroupedLists />,
      //         },
      //         {
      //           path: "/lists/grid-lists",
      //           element: <BrowseGridLists />,
      //         },
      //         {
      //           path: "/typography",
      //           element: <BrowseTypography />,
      //         },
      //       ],
      //     },
      //     {
      //       path: "checkout",
      //       element: <Checkout />,
      //     },
      //     {
      //       path: "pricing",
      //       element: <Pricing />,
      //     },
      //     {
      //       path: "401",
      //       element: <AuthorizationRequired />,
      //     },
      //     {
      //       path: "404",
      //       element: <NotFound />,
      //     },
      //     {
      //       path: "500",
      //       element: <ServerError />,
      //     },
      //     {
      //       path: "*",
      //       element: <NotFound />,
      // },
    ],
  },
];

export default routes;
