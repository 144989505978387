import firebase from "firebase/app";
import "firebase/auth";
import { firebaseConfig } from "../config";

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Use this instance when creating an employee
export const FirebaseEmployeeService = firebase.initializeApp(
  firebaseConfig,
  "secondaryFirebaseApp"
);

export default firebase;
