import axios from "axios";
import toast from "react-hot-toast";
import firebase from "../lib/firebase";

// Add a request interceptor
const AxiosInstance = axios.create();

AxiosInstance.interceptors.request.use(
  async (config) => {
    const idToken = await firebase.auth().currentUser?.getIdToken();
    config.headers["Content-Type"] = "application/json";
    if (idToken) {
      config.headers.Authorization = idToken;
    }
    return config;
  },
  (error) => {
    // TODO: do something with request error
    console.log("error on creating axios instance ", error);
  }
);

export default AxiosInstance;
